import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { workbox } from '../../../serviceWorker/registerServiceWorker';

const defaultNetworkStatus = {
  hasUsedOfflineForApiOrHtml: false,
  isOnline: true,
  hasFailedActionDueToNetwork: false,
};
export const NetworkStatusContext = React.createContext(defaultNetworkStatus);

const config = getConfig();

export const useNetworkStatusListener = (isOfflinePage = false) => {
  if (!config.publicRuntimeConfig.ENABLE_SERVICE_WORKER) {
    return defaultNetworkStatus;
  }

  const [networkStatus, setNetworkStatus] = useState(() => {
    const initialState = {
      ...defaultNetworkStatus,
      hasUsedOfflineForApiOrHtml: isOfflinePage,
    };

    if (typeof navigator === 'undefined') {
      return initialState;
    }

    initialState.isOnline = navigator.onLine;

    return initialState;
  });
  const router = useRouter();

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      return;
    }

    setNetworkStatus(state => ({ ...state, isOnline: navigator.onLine }));
  }, [setNetworkStatus]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!('serviceWorker' in navigator)) {
      return;
    }

    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data.type !== 'ACTION_NETWORK_ERROR') {
        return;
      }

      setNetworkStatus(state => ({
        ...state,
        hasFailedActionDueToNetwork: true,
      }));
    });
  }, [setNetworkStatus]);

  useEffect(() => {
    if (!networkStatus.hasFailedActionDueToNetwork) {
      return;
    }

    setTimeout(() => {
      setNetworkStatus(state => ({
        ...state,
        hasFailedActionDueToNetwork: false,
      }));
    }, 10000);
  }, [networkStatus.hasFailedActionDueToNetwork]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!('serviceWorker' in navigator)) {
      return;
    }

    const messageListener = event => {
      if (event.data.type !== 'CACHED_RESPONSE_HAS_BEEN_USED') {
        return;
      }

      setNetworkStatus(state => ({
        ...state,
        hasUsedOfflineForApiOrHtml: true,
        isOnline: false,
      }));

      if (event.data.messageId && workbox) {
        workbox.messageSW({
          type: 'ACK_CACHED_RESPONSE_HAS_BEEN_USED',
          messageId: event.data.messageId,
        });
      }
    };

    navigator.serviceWorker.addEventListener('message', messageListener);

    return () => {
      if (!('serviceWorker' in navigator)) {
        return;
      }
      navigator.serviceWorker.removeEventListener('message', messageListener);
    };
  }, [setNetworkStatus]);

  useEffect(() => {
    const resetHasUsedOfflineForApiOrHtml = () => {
      if (networkStatus.hasUsedOfflineForApiOrHtml) {
        setNetworkStatus(state => ({
          ...state,
          hasUsedOfflineForApiOrHtml: false,
        }));
      }
    };

    router.events.on('routeChangeStart', resetHasUsedOfflineForApiOrHtml);

    return () => {
      router.events.off('routeChangeStart', resetHasUsedOfflineForApiOrHtml);
    };
  }, [router, networkStatus, setNetworkStatus]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const networkOnline = () => {
      setNetworkStatus(state => ({ ...state, isOnline: true }));
    };

    const networkOffline = () => {
      setNetworkStatus(state => ({ ...state, isOnline: false }));
    };

    window.addEventListener('offline', networkOffline);
    window.addEventListener('online', networkOnline);

    return () => {
      window.removeEventListener('offline', networkOffline);
      window.removeEventListener('online', networkOnline);
    };
  }, [setNetworkStatus]);

  return networkStatus;
};

export const useNetworkStatus = () => {
  return useContext(NetworkStatusContext);
};
