import { Global, css } from '@emotion/react';

export const globalStyle = css`
  /* My own light CSS reset */
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
  }
  html {
    scroll-behavior: smooth;
  }
  .screen-reader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  iframe {
    /* Usefull for embeded video players */
    max-width: 100%;
    height: auto;
  }
  abbr {
    cursor: help;
  }
  @media print {
    * {
      overflow: visible !important;
    }
    html,
    body {
      min-height: 100%;
    }
  }
`;

export default function GlobalStyles() {
  return <Global styles={globalStyle} />;
}
