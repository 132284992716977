import { useEffect, useMemo } from 'react';

declare global {
  interface Window {
    tarteaucitron: TarteAuCitron;
    tarteaucitronCustomText: any;
  }
}

type TarteAuCitron = {
  load: () => void;
  init: (config: TarteAuCitronConfig) => void;
  user: {
    googletagmanagerId: string;
  };
  job: string[];
  state: string[];
  cookie: {
    read: () => string;
  };
};

type TarteAuCitronConfig = {
  privacyUrl?: string /* Privacy policy url */;
  bodyPosition?:
    | 'bottom'
    | 'top' /* or top to bring it as first element for accessibility */;

  hashtag?: string /* Open the panel with this hashtag */;
  cookieName?: string /* Cookie name */;

  orientation?:
    | 'top'
    | 'bottom'
    | 'middle'
    | 'popup' /* Banner position (top - bottom - middle - popup) */;

  groupServices?: boolean /* Group services by category */;
  showDetailsOnClick?: boolean /* Click to expand the description */;
  serviceDefaultState?:
    | 'true'
    | 'wait'
    | 'false' /* Default state (true - wait - false) */;

  showAlertSmall?: boolean /* Show the small banner on bottom right */;
  cookieslist?: boolean /* Show the cookie list */;

  showIcon?: boolean /* Show cookie icon to manage cookies */;
  iconSrc?: string /* Optional: URL or base64 encoded image */;
  iconPosition?:
    | 'BottomRight'
    | 'BottomLeft'
    | 'TopRight'
    | 'TopLeft' /* Position of the icon */;

  adblocker?: boolean /* Show a Warning if an adblocker is detected */;

  DenyAllCta?: boolean /* Show the deny all button */;
  AcceptAllCta?: boolean /* Show the accept all button when highPrivacy on */;
  highPrivacy?: boolean /* HIGHLY RECOMMENDED Disable auto consent */;
  alwaysNeedConsent?: boolean /* Ask the consent for "Privacy by design" services */;

  handleBrowserDNTRequest?: boolean /* If Do Not Track == 1, disallow all */;

  removeCredit?: boolean /* Remove credit link */;
  moreInfoLink?: boolean /* Show more info link */;
  useExternalCss?: boolean /* If false, the tarteaucitron.css file will be loaded */;
  useExternalJs?: boolean /* If false, the tarteaucitron.services.js file will be loaded */;

  cookieDomain?: string /* Shared cookie for subdomain website */;

  readmoreLink?: string /* Change the default readmore link pointing to tarteaucitron.io */;

  mandatory?: boolean /* Show a message about mandatory cookies */;
  mandatoryCta?: boolean /* Show the disabled accept button when mandatory on */;

  customCloserId?: string /* Optional a11y: Custom element ID used to open the panel */;

  googleConsentMode?: boolean /* Enable Google Consent Mode v2 for Google ads and GA4 */;

  partnersList?: boolean /* Details the number of partners on the popup and middle banner */;
};

const getConfig = require('next/config').default;
const envConfig = getConfig().publicRuntimeConfig;

const gtmSiteId = envConfig.GOOGLE_TAG_MANAGER_SITE_ID;
export default function TarteAuCitron() {
  const certificationUrl: string = useMemo(() => {
    const host = envConfig.HOST;
    return `${host}/politique-de-confidentialite`;
  }, [envConfig.HOST]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.tarteaucitron &&
      window.tarteaucitron.state &&
      Object.keys(window.tarteaucitron.state).length === 0
    ) {
      // Configuration is not in the component but in the web interface of TarteAuCitron because
      // we have the paid version of the service

      if (gtmSiteId) {
        window.tarteaucitron.user.googletagmanagerId = gtmSiteId;
        (window.tarteaucitron.job = window.tarteaucitron.job || []).push(
          'googletagmanager'
        );
      }
    }
  }, []);

  return null;
}
