import { Workbox } from 'workbox-window';
import getConfig from 'next/config';

export let workbox = null;

export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      let queryString = `buildId=${__NEXT_DATA__.buildId}`;
      if (
        typeof __BUILD_MANIFEST !== 'undefined' &&
        typeof __BUILD_MANIFEST['/offline'] !== 'undefined'
      ) {
        queryString = `${queryString}&offlineScripts=${__BUILD_MANIFEST[
          '/offline'
        ].join(',')}`;
      }

      const config = getConfig();

      queryString = `${queryString}&env=${config.publicRuntimeConfig.ENV}`;

      workbox = new Workbox(`/service-worker.js?${queryString}`);

      workbox.register();
    });
  }
}
